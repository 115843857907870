/**
 * STEGO Label Manager API
 * The STEGO Label Manager API handles all portal functionality.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@stego.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CustomDateType } from '../model/models';
import { ProblemDetails } from '../model/models';
import { VariantDto } from '../model/models';
import { VariantGroupDto } from '../model/models';
import { VariantGroupDtoItemsResult } from '../model/models';
import { VariantSelectionDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class VariantGroupService {

    protected basePath = 'http://localhost:33333';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param variantGroupId 
     * @param masterArticleId 
     * @param additionalInformation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createVariant(variantGroupId: string, masterArticleId?: string, additionalInformation?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<string>;
    public createVariant(variantGroupId: string, masterArticleId?: string, additionalInformation?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<string>>;
    public createVariant(variantGroupId: string, masterArticleId?: string, additionalInformation?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<string>>;
    public createVariant(variantGroupId: string, masterArticleId?: string, additionalInformation?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (variantGroupId === null || variantGroupId === undefined) {
            throw new Error('Required parameter variantGroupId was null or undefined when calling createVariant.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (masterArticleId !== undefined && masterArticleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>masterArticleId, 'masterArticleId');
        }
        if (additionalInformation !== undefined && additionalInformation !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>additionalInformation, 'additionalInformation');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<string>(`${this.configuration.basePath}/api/variantGroup/${encodeURIComponent(String(variantGroupId))}/variants`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerName 
     * @param customerDisplayName 
     * @param customDateType 
     * @param dateFormat 
     * @param dateCodesFile 
     * @param variantGroupAttributeValues 
     * @param customerLogo1AttributeId 
     * @param customerLogo1Name 
     * @param customerLogo1File 
     * @param customerLogo1FileName 
     * @param customerLogo1Selected 
     * @param customerLogo2AttributeId 
     * @param customerLogo2Name 
     * @param customerLogo2File 
     * @param customerLogo2FileName 
     * @param customerLogo2Selected 
     * @param customerLogo3AttributeId 
     * @param customerLogo3Name 
     * @param customerLogo3File 
     * @param customerLogo3FileName 
     * @param customerLogo3Selected 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createVariantGroup(customerName?: string, customerDisplayName?: string, customDateType?: CustomDateType, dateFormat?: string, dateCodesFile?: Blob, variantGroupAttributeValues?: string, customerLogo1AttributeId?: string, customerLogo1Name?: string, customerLogo1File?: Blob, customerLogo1FileName?: string, customerLogo1Selected?: boolean, customerLogo2AttributeId?: string, customerLogo2Name?: string, customerLogo2File?: Blob, customerLogo2FileName?: string, customerLogo2Selected?: boolean, customerLogo3AttributeId?: string, customerLogo3Name?: string, customerLogo3File?: Blob, customerLogo3FileName?: string, customerLogo3Selected?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<VariantGroupDto>;
    public createVariantGroup(customerName?: string, customerDisplayName?: string, customDateType?: CustomDateType, dateFormat?: string, dateCodesFile?: Blob, variantGroupAttributeValues?: string, customerLogo1AttributeId?: string, customerLogo1Name?: string, customerLogo1File?: Blob, customerLogo1FileName?: string, customerLogo1Selected?: boolean, customerLogo2AttributeId?: string, customerLogo2Name?: string, customerLogo2File?: Blob, customerLogo2FileName?: string, customerLogo2Selected?: boolean, customerLogo3AttributeId?: string, customerLogo3Name?: string, customerLogo3File?: Blob, customerLogo3FileName?: string, customerLogo3Selected?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<VariantGroupDto>>;
    public createVariantGroup(customerName?: string, customerDisplayName?: string, customDateType?: CustomDateType, dateFormat?: string, dateCodesFile?: Blob, variantGroupAttributeValues?: string, customerLogo1AttributeId?: string, customerLogo1Name?: string, customerLogo1File?: Blob, customerLogo1FileName?: string, customerLogo1Selected?: boolean, customerLogo2AttributeId?: string, customerLogo2Name?: string, customerLogo2File?: Blob, customerLogo2FileName?: string, customerLogo2Selected?: boolean, customerLogo3AttributeId?: string, customerLogo3Name?: string, customerLogo3File?: Blob, customerLogo3FileName?: string, customerLogo3Selected?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<VariantGroupDto>>;
    public createVariantGroup(customerName?: string, customerDisplayName?: string, customDateType?: CustomDateType, dateFormat?: string, dateCodesFile?: Blob, variantGroupAttributeValues?: string, customerLogo1AttributeId?: string, customerLogo1Name?: string, customerLogo1File?: Blob, customerLogo1FileName?: string, customerLogo1Selected?: boolean, customerLogo2AttributeId?: string, customerLogo2Name?: string, customerLogo2File?: Blob, customerLogo2FileName?: string, customerLogo2Selected?: boolean, customerLogo3AttributeId?: string, customerLogo3Name?: string, customerLogo3File?: Blob, customerLogo3FileName?: string, customerLogo3Selected?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (customerName !== undefined) {
            formParams = formParams.append('CustomerName', <any>customerName) as any || formParams;
        }
        if (customerDisplayName !== undefined) {
            formParams = formParams.append('CustomerDisplayName', <any>customerDisplayName) as any || formParams;
        }
        if (customDateType !== undefined) {
            formParams = formParams.append('CustomDateType', <any>customDateType) as any || formParams;
        }
        if (dateFormat !== undefined) {
            formParams = formParams.append('DateFormat', <any>dateFormat) as any || formParams;
        }
        if (dateCodesFile !== undefined) {
            formParams = formParams.append('DateCodesFile', <any>dateCodesFile) as any || formParams;
        }
        if (variantGroupAttributeValues !== undefined) {
            formParams = formParams.append('VariantGroupAttributeValues', <any>variantGroupAttributeValues) as any || formParams;
        }
        if (customerLogo1AttributeId !== undefined) {
            formParams = formParams.append('CustomerLogo1.AttributeId', <any>customerLogo1AttributeId) as any || formParams;
        }
        if (customerLogo1Name !== undefined) {
            formParams = formParams.append('CustomerLogo1.Name', <any>customerLogo1Name) as any || formParams;
        }
        if (customerLogo1File !== undefined) {
            formParams = formParams.append('CustomerLogo1.File', <any>customerLogo1File) as any || formParams;
        }
        if (customerLogo1FileName !== undefined) {
            formParams = formParams.append('CustomerLogo1.FileName', <any>customerLogo1FileName) as any || formParams;
        }
        if (customerLogo1Selected !== undefined) {
            formParams = formParams.append('CustomerLogo1.Selected', <any>customerLogo1Selected) as any || formParams;
        }
        if (customerLogo2AttributeId !== undefined) {
            formParams = formParams.append('CustomerLogo2.AttributeId', <any>customerLogo2AttributeId) as any || formParams;
        }
        if (customerLogo2Name !== undefined) {
            formParams = formParams.append('CustomerLogo2.Name', <any>customerLogo2Name) as any || formParams;
        }
        if (customerLogo2File !== undefined) {
            formParams = formParams.append('CustomerLogo2.File', <any>customerLogo2File) as any || formParams;
        }
        if (customerLogo2FileName !== undefined) {
            formParams = formParams.append('CustomerLogo2.FileName', <any>customerLogo2FileName) as any || formParams;
        }
        if (customerLogo2Selected !== undefined) {
            formParams = formParams.append('CustomerLogo2.Selected', <any>customerLogo2Selected) as any || formParams;
        }
        if (customerLogo3AttributeId !== undefined) {
            formParams = formParams.append('CustomerLogo3.AttributeId', <any>customerLogo3AttributeId) as any || formParams;
        }
        if (customerLogo3Name !== undefined) {
            formParams = formParams.append('CustomerLogo3.Name', <any>customerLogo3Name) as any || formParams;
        }
        if (customerLogo3File !== undefined) {
            formParams = formParams.append('CustomerLogo3.File', <any>customerLogo3File) as any || formParams;
        }
        if (customerLogo3FileName !== undefined) {
            formParams = formParams.append('CustomerLogo3.FileName', <any>customerLogo3FileName) as any || formParams;
        }
        if (customerLogo3Selected !== undefined) {
            formParams = formParams.append('CustomerLogo3.Selected', <any>customerLogo3Selected) as any || formParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<VariantGroupDto>(`${this.configuration.basePath}/api/variantGroup`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param version 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteVariantGroup(id: string, version?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteVariantGroup(id: string, version?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteVariantGroup(id: string, version?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteVariantGroup(id: string, version?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteVariantGroup.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (version !== undefined && version !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>version, 'version');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/variantGroup/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param variantGroupId 
     * @param customDateConfigurationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadCustomDateCodesFile(variantGroupId: string, customDateConfigurationId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<Blob>;
    public downloadCustomDateCodesFile(variantGroupId: string, customDateConfigurationId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpResponse<Blob>>;
    public downloadCustomDateCodesFile(variantGroupId: string, customDateConfigurationId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpEvent<Blob>>;
    public downloadCustomDateCodesFile(variantGroupId: string, customDateConfigurationId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<any> {
        if (variantGroupId === null || variantGroupId === undefined) {
            throw new Error('Required parameter variantGroupId was null or undefined when calling downloadCustomDateCodesFile.');
        }
        if (customDateConfigurationId === null || customDateConfigurationId === undefined) {
            throw new Error('Required parameter customDateConfigurationId was null or undefined when calling downloadCustomDateCodesFile.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/variantGroup/${encodeURIComponent(String(variantGroupId))}/${encodeURIComponent(String(customDateConfigurationId))}/download`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param variantGroupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrintableVariantsForVariantGroup(variantGroupId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<VariantSelectionDto>>;
    public getPrintableVariantsForVariantGroup(variantGroupId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<VariantSelectionDto>>>;
    public getPrintableVariantsForVariantGroup(variantGroupId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<VariantSelectionDto>>>;
    public getPrintableVariantsForVariantGroup(variantGroupId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (variantGroupId === null || variantGroupId === undefined) {
            throw new Error('Required parameter variantGroupId was null or undefined when calling getPrintableVariantsForVariantGroup.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<VariantSelectionDto>>(`${this.configuration.basePath}/api/variantGroup/${encodeURIComponent(String(variantGroupId))}/variants/printable`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param page 
     * @param size 
     * @param orderBy 
     * @param orderDirection 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRawVariantGroups(page?: number, size?: number, orderBy?: string, orderDirection?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<VariantGroupDtoItemsResult>;
    public getRawVariantGroups(page?: number, size?: number, orderBy?: string, orderDirection?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<VariantGroupDtoItemsResult>>;
    public getRawVariantGroups(page?: number, size?: number, orderBy?: string, orderDirection?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<VariantGroupDtoItemsResult>>;
    public getRawVariantGroups(page?: number, size?: number, orderBy?: string, orderDirection?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>size, 'size');
        }
        if (orderBy !== undefined && orderBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderBy, 'orderBy');
        }
        if (orderDirection !== undefined && orderDirection !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderDirection, 'orderDirection');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<VariantGroupDtoItemsResult>(`${this.configuration.basePath}/api/variantGroup/raw`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param variantGroupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVariantGroup(variantGroupId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<VariantGroupDto>;
    public getVariantGroup(variantGroupId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<VariantGroupDto>>;
    public getVariantGroup(variantGroupId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<VariantGroupDto>>;
    public getVariantGroup(variantGroupId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (variantGroupId === null || variantGroupId === undefined) {
            throw new Error('Required parameter variantGroupId was null or undefined when calling getVariantGroup.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<VariantGroupDto>(`${this.configuration.basePath}/api/variantGroup/${encodeURIComponent(String(variantGroupId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param variantGroupId 
     * @param variantId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVariantGroupWithVariantDetails(variantGroupId: string, variantId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<VariantDto>;
    public getVariantGroupWithVariantDetails(variantGroupId: string, variantId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<VariantDto>>;
    public getVariantGroupWithVariantDetails(variantGroupId: string, variantId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<VariantDto>>;
    public getVariantGroupWithVariantDetails(variantGroupId: string, variantId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (variantGroupId === null || variantGroupId === undefined) {
            throw new Error('Required parameter variantGroupId was null or undefined when calling getVariantGroupWithVariantDetails.');
        }
        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling getVariantGroupWithVariantDetails.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<VariantDto>(`${this.configuration.basePath}/api/variantGroup/${encodeURIComponent(String(variantGroupId))}/variants/${encodeURIComponent(String(variantId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param page 
     * @param size 
     * @param orderBy 
     * @param orderDirection 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVariantGroups(page?: number, size?: number, orderBy?: string, orderDirection?: string, searchText?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<VariantGroupDtoItemsResult>;
    public getVariantGroups(page?: number, size?: number, orderBy?: string, orderDirection?: string, searchText?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<VariantGroupDtoItemsResult>>;
    public getVariantGroups(page?: number, size?: number, orderBy?: string, orderDirection?: string, searchText?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<VariantGroupDtoItemsResult>>;
    public getVariantGroups(page?: number, size?: number, orderBy?: string, orderDirection?: string, searchText?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>size, 'size');
        }
        if (orderBy !== undefined && orderBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderBy, 'orderBy');
        }
        if (orderDirection !== undefined && orderDirection !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderDirection, 'orderDirection');
        }
        if (searchText !== undefined && searchText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>searchText, 'searchText');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<VariantGroupDtoItemsResult>(`${this.configuration.basePath}/api/variantGroup`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param customerName 
     * @param customerDisplayName 
     * @param customDateType 
     * @param dateFormat 
     * @param dateCodesFile 
     * @param variantGroupAttributeValues 
     * @param customerLogo1AttributeId 
     * @param customerLogo1Name 
     * @param customerLogo1File 
     * @param customerLogo1FileName 
     * @param customerLogo1Selected 
     * @param customerLogo2AttributeId 
     * @param customerLogo2Name 
     * @param customerLogo2File 
     * @param customerLogo2FileName 
     * @param customerLogo2Selected 
     * @param customerLogo3AttributeId 
     * @param customerLogo3Name 
     * @param customerLogo3File 
     * @param customerLogo3FileName 
     * @param customerLogo3Selected 
     * @param version 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateVariantGroup(id: string, customerName?: string, customerDisplayName?: string, customDateType?: CustomDateType, dateFormat?: string, dateCodesFile?: Blob, variantGroupAttributeValues?: string, customerLogo1AttributeId?: string, customerLogo1Name?: string, customerLogo1File?: Blob, customerLogo1FileName?: string, customerLogo1Selected?: boolean, customerLogo2AttributeId?: string, customerLogo2Name?: string, customerLogo2File?: Blob, customerLogo2FileName?: string, customerLogo2Selected?: boolean, customerLogo3AttributeId?: string, customerLogo3Name?: string, customerLogo3File?: Blob, customerLogo3FileName?: string, customerLogo3Selected?: boolean, version?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public updateVariantGroup(id: string, customerName?: string, customerDisplayName?: string, customDateType?: CustomDateType, dateFormat?: string, dateCodesFile?: Blob, variantGroupAttributeValues?: string, customerLogo1AttributeId?: string, customerLogo1Name?: string, customerLogo1File?: Blob, customerLogo1FileName?: string, customerLogo1Selected?: boolean, customerLogo2AttributeId?: string, customerLogo2Name?: string, customerLogo2File?: Blob, customerLogo2FileName?: string, customerLogo2Selected?: boolean, customerLogo3AttributeId?: string, customerLogo3Name?: string, customerLogo3File?: Blob, customerLogo3FileName?: string, customerLogo3Selected?: boolean, version?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public updateVariantGroup(id: string, customerName?: string, customerDisplayName?: string, customDateType?: CustomDateType, dateFormat?: string, dateCodesFile?: Blob, variantGroupAttributeValues?: string, customerLogo1AttributeId?: string, customerLogo1Name?: string, customerLogo1File?: Blob, customerLogo1FileName?: string, customerLogo1Selected?: boolean, customerLogo2AttributeId?: string, customerLogo2Name?: string, customerLogo2File?: Blob, customerLogo2FileName?: string, customerLogo2Selected?: boolean, customerLogo3AttributeId?: string, customerLogo3Name?: string, customerLogo3File?: Blob, customerLogo3FileName?: string, customerLogo3Selected?: boolean, version?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public updateVariantGroup(id: string, customerName?: string, customerDisplayName?: string, customDateType?: CustomDateType, dateFormat?: string, dateCodesFile?: Blob, variantGroupAttributeValues?: string, customerLogo1AttributeId?: string, customerLogo1Name?: string, customerLogo1File?: Blob, customerLogo1FileName?: string, customerLogo1Selected?: boolean, customerLogo2AttributeId?: string, customerLogo2Name?: string, customerLogo2File?: Blob, customerLogo2FileName?: string, customerLogo2Selected?: boolean, customerLogo3AttributeId?: string, customerLogo3Name?: string, customerLogo3File?: Blob, customerLogo3FileName?: string, customerLogo3Selected?: boolean, version?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateVariantGroup.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (customerName !== undefined) {
            formParams = formParams.append('CustomerName', <any>customerName) as any || formParams;
        }
        if (customerDisplayName !== undefined) {
            formParams = formParams.append('CustomerDisplayName', <any>customerDisplayName) as any || formParams;
        }
        if (customDateType !== undefined) {
            formParams = formParams.append('CustomDateType', <any>customDateType) as any || formParams;
        }
        if (dateFormat !== undefined) {
            formParams = formParams.append('DateFormat', <any>dateFormat) as any || formParams;
        }
        if (dateCodesFile !== undefined) {
            formParams = formParams.append('DateCodesFile', <any>dateCodesFile) as any || formParams;
        }
        if (variantGroupAttributeValues !== undefined) {
            formParams = formParams.append('VariantGroupAttributeValues', <any>variantGroupAttributeValues) as any || formParams;
        }
        if (customerLogo1AttributeId !== undefined) {
            formParams = formParams.append('CustomerLogo1.AttributeId', <any>customerLogo1AttributeId) as any || formParams;
        }
        if (customerLogo1Name !== undefined) {
            formParams = formParams.append('CustomerLogo1.Name', <any>customerLogo1Name) as any || formParams;
        }
        if (customerLogo1File !== undefined) {
            formParams = formParams.append('CustomerLogo1.File', <any>customerLogo1File) as any || formParams;
        }
        if (customerLogo1FileName !== undefined) {
            formParams = formParams.append('CustomerLogo1.FileName', <any>customerLogo1FileName) as any || formParams;
        }
        if (customerLogo1Selected !== undefined) {
            formParams = formParams.append('CustomerLogo1.Selected', <any>customerLogo1Selected) as any || formParams;
        }
        if (customerLogo2AttributeId !== undefined) {
            formParams = formParams.append('CustomerLogo2.AttributeId', <any>customerLogo2AttributeId) as any || formParams;
        }
        if (customerLogo2Name !== undefined) {
            formParams = formParams.append('CustomerLogo2.Name', <any>customerLogo2Name) as any || formParams;
        }
        if (customerLogo2File !== undefined) {
            formParams = formParams.append('CustomerLogo2.File', <any>customerLogo2File) as any || formParams;
        }
        if (customerLogo2FileName !== undefined) {
            formParams = formParams.append('CustomerLogo2.FileName', <any>customerLogo2FileName) as any || formParams;
        }
        if (customerLogo2Selected !== undefined) {
            formParams = formParams.append('CustomerLogo2.Selected', <any>customerLogo2Selected) as any || formParams;
        }
        if (customerLogo3AttributeId !== undefined) {
            formParams = formParams.append('CustomerLogo3.AttributeId', <any>customerLogo3AttributeId) as any || formParams;
        }
        if (customerLogo3Name !== undefined) {
            formParams = formParams.append('CustomerLogo3.Name', <any>customerLogo3Name) as any || formParams;
        }
        if (customerLogo3File !== undefined) {
            formParams = formParams.append('CustomerLogo3.File', <any>customerLogo3File) as any || formParams;
        }
        if (customerLogo3FileName !== undefined) {
            formParams = formParams.append('CustomerLogo3.FileName', <any>customerLogo3FileName) as any || formParams;
        }
        if (customerLogo3Selected !== undefined) {
            formParams = formParams.append('CustomerLogo3.Selected', <any>customerLogo3Selected) as any || formParams;
        }
        if (version !== undefined) {
            formParams = formParams.append('Version', <any>version) as any || formParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/variantGroup/${encodeURIComponent(String(id))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
